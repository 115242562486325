import { RefObject, useLayoutEffect, useRef, useState } from 'react'

export function useContainerZoom({
  pageHeight,
  pageWidth,
}: {
  pageHeight: number
  pageWidth: number
}): [RefObject<HTMLDivElement>, number] {
  const [zoom, setZoom] = useState(0)
  const containerRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (!containerRef.current) return
    const bounds = containerRef.current.getBoundingClientRect()
    setZoom(bounds.width / pageWidth)
    const observer = new ResizeObserver((entries) => {
      const bounds = entries[0]?.contentRect
      bounds && setZoom(bounds.width / pageWidth)
    })
    observer.observe(containerRef.current)
    return () => observer.disconnect()
  }, [pageHeight, pageWidth])

  return [containerRef, zoom]
}
