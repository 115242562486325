import { ComponentProps, FC, useState } from 'react'
import { cn } from 'utils'
import { PdfDocument, PdfPageDefinition } from './pdf-document'
import { PdfPage } from './pdf-page'
import styles from './pdf-viewer.module.scss'

type Props = Omit<
  ComponentProps<typeof PdfPage>,
  'pdf' | 'className' | 'pageWidth' | 'pageHeight' | 'id' | 'onError'
> &
  Pick<ComponentProps<typeof PdfDocument>, 'onError'> & {
    className?: string
    pageClassName?: string
    renderPageContent?: (page: {
      width: number
      height: number
      id: number
      zoom: number
    }) => JSX.Element
    pdf: Blob
  }

export const PdfViewer: FC<Props> = ({
  pdf,
  className,
  pageClassName,
  renderPageContent,
  onError,
  ...props
}) => {
  const [pages, setPages] = useState<PdfPageDefinition[]>([])
  return (
    <PdfDocument
      pdf={pdf}
      onLoadPages={setPages}
      className={cn(styles.doc, className)}
      onError={onError}
    >
      {pages.map((page) => (
        <div key={page.id} className={styles.part}>
          <PdfPage
            {...props}
            pageWidth={page.width}
            pageHeight={page.height}
            id={page.id}
            pageClassName={pageClassName}
          >
            {renderPageContent}
          </PdfPage>
          <div className={styles.pageNumber}>
            {page.id + 1} / {pages.length}
          </div>
        </div>
      ))}
    </PdfDocument>
  )
}
